// 客製用
//================================================================================

// @include media-breakpoint-down(xs) {}
// @include media-breakpoint-down(sm) {}
// @include media-breakpoint-down(md) {}
// @include media-breakpoint-down(lg) {} XXXX以下
// @include media-breakpoint-between(md, xl) {}  特殊區間
// @include media-breakpoint-up(sm) { ... }   XXXX以上

//================================================================================

//================================================================================
/* 零件區 */
//================================================================================
.form-group {
  > label {
    font-weight: $font-weight-normal;
  }

  &.required {
    > label {
      position: relative;
      &::after {
        content: "*";
        color: $danger;
        vertical-align: text-top;
        font-size: 0.8em;
      }
    }
  }
}

.btn-more {
  @extend .btn-outline-dark;
  font-weight: $font-weight-bold;
  padding: 1rem 1.25rem;
  span, i {
    font-weight: $font-weight-normal;
  }
}

//pagination
.pagination {
  @include break-down {
    flex-wrap: wrap;
  }
}
.page-item.active .page-link {
  font-weight: $font-weight-bold;
}
.page-link {
  font-family: $font-family-en;
  margin: 0.25rem;
}

.table {
  td {
    .btn:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}

.table-mas {
  border-bottom: solid 1px $gray-800;
  tbody {
    th,td {
      vertical-align: middle;
    }
  }

  &.table-striped {
    th,
    td {
      border-top: 0;
    }
    
  }
}

//================================================================================
/* 列表區 */
//================================================================================

//news list
.infoList {
  .list {
    &-item {
      color: $text-color;
      padding: 1.25rem 0;
      border-bottom: dashed 1px $gray-500;
      &.row {
        margin: 0;
      }
      a {
        color: $text-color;
      }
    }
    &-head {
      display: flex;
      flex-direction: column;
      // @include media-breakpoint-down(md) {
      //   margin: 0 auto 1rem;
      // }
    }
    &-cate, &-date {
      text-align: center;
      line-height: 1.8;
      @include flex-center;
    }
    &-cate {
      color: $white;
      font-weight: $font-weight-bold;
      background-color: $gray-700;
      margin-bottom: 0.5rem
    }
    &-date {
      font-size: 1rem;
      min-height: 2.5rem;
      padding: 0.315rem 1.25rem;
      background-color: $gray-200;
      flex-grow: 1;
      @include media-breakpoint-up (xl) {
        font-size: 1.25rem;
        padding: 0.5rem 1.25rem;
      }
    }
    &-content {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    &-title {
      color: $black;
      font-size: 1.25rem;
      font-weight: $font-weight-bold;
      line-height: 1;
      padding: 0.25rem 0;
      @include text-ellipsis;
    }
    &-text {
      @include break-up {
        @include text-ellipsis;
      }
    }
  }
  
  a.list-item {
    @include hover-focus() {
      background-color: rgba($gray-700, 0.08);
      .list-title {
        color: darken($main-color01, 8%);
      }
      .list-date {
        background-color: $white;
      }
    }
  }
}

.list-tag,
.articles-tag {
  @extend .badge;
  @extend .badge-secondary;
  font-family: $font-family-en;
  font-size: 0.875rem;
  vertical-align: top;
  border-radius: 3px;
  padding: 0.25rem 0.75rem;

  &.app {
    background-color: $main-app;
  }

  &.iot {
    background-color: $main-iot;
  }
}

//================================================================================
/* 首頁區 */
//================================================================================

//首頁banner
.banner {
  // &-inner {}
  &-item {
    @extend .background-img;
    padding-top: 56.25%;
    @include media-breakpoint-up(md) {
      padding-top: 42.708%;
    }
  }
  
  &-title {
    font-size: 1rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.125rem;
    max-height: 3.625rem;
    margin-bottom: 0;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      @include text-ellipsis;
      font-size: 1.5rem;
      max-height: none;
    }
    @include break-up {
      font-size: 2rem;
    }
  }
  .carousel-caption {
    font-size: 1rem;
    letter-spacing: 0.125rem;
    text-shadow: 0 1px 1px rgba($black, 0.2);
    max-width: 100%;
    padding: 0.5rem;
    background-color: rgba($black, 0.5);
    bottom: 2rem;
    @include break-up {
      width: 70%;
      padding: 1rem;
      bottom: 3rem;
      // &.caption-left {
      //   text-align: left;
      //   left: 8.84%;
      // }
      // &.caption-right {
      //   text-align: right;
      //   right: 8.84%;
      //   left: auto;
      // }
      &.caption-center {
        transform: translateX(-50%);
        left: 50%;
        right: auto;
      }
    }
    @include media-breakpoint-up (xl) {
      font-size: 1.125rem;
      width: 50%;
    }
    p {
      max-height: 4.5rem;
      overflow: hidden;
      display: none;
      @include media-breakpoint-up(sm) {
        display: block;
      }
      @include media-breakpoint-up (xl) {
        max-height: 5.0625rem;
      }
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  background-color: rgba($black, 0);
  transition: all .2s ease-in;
  &:hover {
    background-color: rgba($black, 0.07);
  }
}

.indTitle {
  font-size: 2rem;
  font-weight: $font-weight-bold;
  text-align: center;
  position: relative;
  @include break-up {
    font-size: 2.5rem;
  }
  &:after {
    content: " ";
    width: 5rem;
    min-height: 1px;
    border-top: solid 4px $gray-500;
    position: absolute;
    top: calc(100% + 0.625rem);
    left: calc(50% - 2.5rem);
  }
}

.indPassed {
  background: linear-gradient(0deg, rgba($white,0) 40%, rgba($gray-200,1) 40%);
  position: relative;
  z-index: 0;
}

.cert {
  &-card {
    width: 100%;
    padding: 0.625rem;
    padding-bottom: 0;
    background-color: $white;
    box-shadow: $box-shadow-sm;
    position: relative;

    &:before, &:after {
      content: "";
      width: 50%;
      max-width: 300px;
      background: rgba($black, 0.4);
      box-shadow: 0 18px 12px rgba($black, 0.4);
      z-index: -1;
      position: absolute;
      bottom: 16px;
      top: 80%;
    }

    &:before {
      transform: rotate(-3deg);
      left: 10px;
    }

    &:after {
      transform: rotate(3deg);
      right: 10px;
    }
  }

  &-body {
    text-align: center;
    width: 100%;
    padding: 1rem;
    padding-bottom: 18%;
    background: url(../images/img_stamp.svg) no-repeat center;
    background-size: auto;
    border: solid 1px $gray-500;
    border-bottom-color: transparent;
    display: flex;
    flex-direction: column;
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      right: 0;
      bottom: -1px;
      background: url(../images/bg_certificate_app.svg) no-repeat bottom center;
      background-size: 100%;
      pointer-events: none;
    }
  }

  &-title {
    color: $gray-700;
    font-size: 1.125rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: $font-weight-bolder;
    letter-spacing: 0.15rem;
  }

  &-cate {
    font-size: 0.813rem;
    font-weight: $font-weight-normal;
    span {
      color: $main-color01;
      padding: 0 0.25rem;
      display: inline-block;
    }
    &:before,
    &:after {
      content: "—";
    }
  }

  &-name {
    color: $black;
    font-size: 1.125rem;
    font-weight: $font-weight-bolder;
    line-height: 1.2;
    margin-bottom: 1rem;
    span {
      padding: 0.315rem 0;
      display: inline-block;
      border-bottom: solid 1px $gray-700;
    }
    @include break-up {
      font-size: 1.25rem;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    dl {
      min-width: 42%;
      flex-basis: 42%;
      padding-inline-start: 0;
      margin-bottom: 0;
      dt, dd {
        font-weight: $font-weight-normal;
      }
      dt {
        color: $main-color01;
        font-size: 0.75rem;
      }
      dd {
        font-size: 0.875rem;
        max-height: 2.625rem;
        overflow-y: hidden;
      }
    }
  }
}

.cert-app {
  .cert {
    &-body {
      &::after {
        background-image: url(../images/bg_certificate_app.svg);
      }
    }
    &-info dt, 
    &-cate span {
      color: $main-app;
    }
  }
}

.cert-iot {
  .cert {
    &-body {
      &::after {
        background-image: url(../images/bg_certificate_iot.svg);
      }
    }
    &-info dt, 
    &-cate span {
      color: $main-iot;
    }
  }
}

.indNews {
  .infoList {
    li:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .list {
      &-item {
        border-bottom: 0;
        padding: 0;
      }

      &-head {
        @include media-breakpoint-down(md) {
          margin: 0 auto 1rem;
        }
      }

      &-content {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-bottom: dashed 1px $gray-500;
        @include hover-focus() {
          background-color: rgba($gray-700, 0.08);
          .list-title {
            color: darken($main-color01, 8%);
          }
        }
      }
      &-title {
        font-weight: $font-weight-medium;
      }
      &-date {
        font-size: 1.125rem;
        padding: 0.125rem 1.25rem;
      }
    }
  }
}

.pickup {
  width: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1.05) translateY(0.125rem);
  }
  &-container  {
    color: $white;
    text-align: center;
    padding: 1rem;
    background-color: rgba($black, 0.6);
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    .title {
      font-size: 1.25rem;
      font-weight: $font-weight-bold;
      @include text-ellipsis;
    }
  }
}

.intro {
  &-block {
    padding: 4rem 2rem;
    @include media-breakpoint-up(xxl) {
      padding: 4rem;
    }
  }
  &-item {
    width: 100%;
    padding: 1.5rem;
    background-color: $white;
    box-shadow: $box-shadow;
    position: relative;
    @include media-breakpoint-up(xxl) {
      width: 80%;
    }
    &.app, &.iot {
      .picture {
        width: 33%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.app {
      padding-left: 30%;
      .picture {
        left: 0;
      }
    }
    &.iot {
      padding-right: 30%;
      .picture {
        right: -1rem;
      }
    }
  }
  &-title {
    color: darken($gray-700 , 5%);
    font-size: 2rem;
    font-weight: $font-weight-bolder;
  }
  &-text {
    color: $main-color02;
    font-size: 1.125rem;
  }
}

.indResult {
  background: url(../images/bg_polygon.svg) no-repeat top center;
  background-size: 100% auto;
}

.result-count {
  @include flex-center;
  flex-wrap: wrap;
}
.count-item {
  text-align: center;
  padding: 1rem;
  @include make-col-only;
  min-width: 20rem;
  @include media-breakpoint-up(xl) {
    min-width: 15.5rem;
  }
  .item {
    &-icon, &-body {
      @include flex-center;
    }
    &-body {
      width: 20rem;
      max-width: 100%;
      padding: 1.5rem 0.625rem;
      margin: 2rem auto;
      background-color: $white;
      border: 3px solid $main-color01;
      border-radius: $rounded-pill;
      position: relative;
    }
    &-icon {
      color: $white;
      font-size: 1.5rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: solid 3px $white;
      background-color: $main-color01;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-50%);
    }
    &-nums {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2rem;
      font-weight: $font-weight-normal;
      @include media-breakpoint-up(xxl) {
        font-size: 2.5rem;
      }
    }
    &-title {
      font-size: 1.25rem;
      font-weight: $font-weight-bold;
    }
  }
  
  &.app {
    .item {
      &-body {
        border-color: $main-app;
      }
      &-icon {
        background-color: $main-app;
      }
    }
  }
  &.iot {
    .item {
      &-body {
        border-color: $main-iot;
      }
      &-icon {
        background-color: $main-iot;
      }
    }
  }
}

.indAbout {
  background: linear-gradient(180deg, rgba($gray-600,1) 75%, rgba($gray-300,1) 75%);
  @include break-up {
    background: linear-gradient(90deg, rgba($gray-600,1) 75%, rgba($gray-300,1) 75%);
  }
  &-text {
    color: $white;
    font-size: 1.125rem;
    line-height: 1.8;
    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
    }
  }
  .indTitle {
    color: $white;
    text-align: left;
    &:after {
      left: 0;
    }
  }
}



//================================================================================
/* 內頁區 */
//================================================================================

.pageHead {
  color: $white;
  background: linear-gradient(45deg, rgba(0,128,153,1) 10%, rgba(0,94,189,1) 70%, rgba(0,83,167,1) 100%);
  margin-bottom: 2.5rem;
  position: relative;
  &::before {
    content: "";
    @include absolute-full;
    background: url(../images/pagehead_bg.svg) no-repeat center;
    background-size: cover;
    pointer-events: none;
    z-index: 0;
  }
  > *[class*="container"] {
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    z-index: 2;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  &-title {
    text-shadow: 6px 0 0 rgba($black, 0.24);
    padding: 1rem 0;
    @include media-breakpoint-down(sm) {
      text-align: center;
      padding-bottom: 0;
    }
    .small {
      text-transform: uppercase;
    }
  }
  h2 {
    font-size: 2rem;
    font-weight: $font-weight-bolder;
    @include break-down {
      font-size: 1.5rem;
    }
  }
}

.pageBtn {
  .btn {
    margin: 0.25rem;
  }
}

.pageSearch {
  .form-inline {
    .form-control, .custom-select {
      @include media-breakpoint-up(md) {
        min-width: 10.5rem;
      }
      @include media-breakpoint-up(xxl) {
        min-width: 15.5rem;
      }
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: initial;
      .form-control,
      .custom-select {
        width: 100%;
        display: block;
      }
    }
  }
  .form-control,
  .custom-select {
    background-color: $gray-200;
    &:focus, &:hover {
      background-color: $input-focus-bg;
    }
  }
  
}

.breadcrumb {
  font-size: 0.913rem;
  margin-bottom: 0;
  &-item {
    font-weight: $font-weight-normal;
    text-shadow: 0 1px 2px rgba($black, 0.4);
    a {
      color: $white;
    }
  }
  
}

.headTab {
  text-align: center;
  @include media-breakpoint-down(xs) {
    .btn-group {
      flex-wrap: wrap;
      justify-content: center;
      .btn-tab {
        flex: 0 0 auto;
      }
    }
  }
  .btn-tab {
    color: $main-color02;
    font-size: 0.875rem;
    font-weight: $font-weight-bold;
    background-color: rgba($main-color02, 0.08);
    border-color: rgba($main-color02, 0.16);
    border-left-style: dashed;
    border-right-style: dashed;
    &:active, &.active, &:hover {
      color: $white;
      background-color: $main-color02;
      border-color: $main-color02;
    }
    &:first-child {
      border-left-style: solid !important;
    }
    &:last-child {
      border-right-style: solid !important;
    }
    @include break-up {
      font-size: 0.938rem;
      min-width: 10rem;
    }
  }
}

.login {
  border: none;
  background-color: rgba($white, 0.01);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 30px 30px -7px;
  .card {
    &-header {
      color: $white;
      background-color: $gray-700;
      &-tabs {
        justify-content: center;
        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
        }
        .nav-link {
          padding-bottom: 0.75rem;
          &.active {
            font-weight: $font-weight-bold;
            box-shadow: 2px -2px 3px rgba($black, 0.4);
          }
          &:not(.active) {
            color: $gray-200;
            font-weight: $font-weight-normal;
          }
        }
      }
    }
    &-body {
      .btn-lg {
        width: 100%;
        margin: 0.125rem;
        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }
      .form-group {
        margin-bottom: 1.25rem;
        label {
          font-weight: $font-weight-normal;
        }
      }
    }
    &-footer {
      a, .btn {
        font-weight: $font-weight-light;
      }
    }
  }

}

.sec-title {
  color: $gray-800;
  font-size: 1.375rem;
  font-weight: $font-weight-bold;
  > i {
    color: $main-color01;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
  }
}

.articles {
  @include media-breakpoint-up(xl) {
    font-size: 1.125rem;
    ol, ul {
      table {
        font-size: 1rem;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    h3.h2 {
      font-size: 1.75rem;
    }
  }
  h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: $font-weight-bold;
  }
  p {
    margin-bottom: 0.625rem;
  }

  &-head {
    padding-bottom: 1rem;
    border-bottom: dashed 1px $gray-600;
    @include flex-align-center;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    .date {
      color: $gray-600;
      font-family: $font-family-en;
      font-size: 0.938rem;
      font-weight: $font-weight-bold;
      white-space: nowrap;
    }
  }

  &-tag {
    line-height: 1.35;
  }

  &-title {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    @include media-breakpoint-up(xl) {
      font-size: 2rem;
    }
  }
}

.editors {
  color: $black;
  line-height: 1.8;
  img {
    @extend .img-fluid;
  }
  table {
    @extend .table;
  }
  table:not([class]) {
    border: solid 1px $table-border-color;
  }
  @include break-down {
    overflow-x: auto;
  }
}

.annex {
  padding: 1rem;
  border: dashed 1px $gray-500;

  &-files {
    @include flex-align-center;
    flex-wrap: wrap;
  }
  &-link {
    color: $gray-800;
    font-size: 0.938rem;
    white-space: normal;
    background-color: $gray-300;
    border-color: $gray-300;
    padding: 0.5rem 0.75rem;
    margin: 0.5rem 0;
    @include hover-focus {
      background-color: $gray-200;
      border-color: $gray-500;
    }
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    i {
      color: $gray-600;
    }
  }
}

.qna {
  border: 0;
  margin-bottom: 2.5rem;
  .card {
    &-header {
      font-size: 1.25rem;
      font-weight: $font-weight-normal;
      padding: 0.75rem 0;
      background-color: transparent;
      border-bottom: solid 2px $main-color01;
      @include flex-align-center;
    }
    &-body {
      font-size: 1.063rem;
    }
  }
}

//網站地圖
.sitemapList {
  a {
    color: $text-color;
    width: 100%;
    display: block;

    &:hover,
    &:focus,
    &:active {
      color: $main-color01;
    }
  }

  >li {
    margin-bottom: 1.5rem;

    .title {
      font-size: 1.125rem;
      font-weight: 700;
      padding: 0;

      @include media-breakpoint-up(xl) {
        font-size: 1.25rem;
      }

      >a,
      >span {
        padding: 1.25rem 1rem;
        display: block;
        background-color: $gray-200;
        border-left: solid 0 transparent;
        transition: all .3s ease-in;
      }

      >a:hover,
      >a:focus,
      >a:active {
        color: $main-color01;
        background-color: $white;
        border-left: solid 0.315rem $main-color02;
        box-shadow: $box-shadow;
      }
    }
  }

  .sublist {
    li {
      margin: 1rem 0;

      a {
        color: $gray-700;
        font-weight: $font-weight-bold;
        padding: 0.625rem 1rem;
        border: dashed 1px $gray-500;

        &:hover,
        &:focus,
        &:active {
          padding-left: 1.5rem;
          background-color: rgba($main-color01, 0.05);
          border-color: transparent;
        }
      }
    }
  }
}

.frules {
  > ul, > ol {
    > li {
      display: flex;
      margin-bottom: 1rem;
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
      > span {
        font-weight: $font-weight-normal;
        white-space: nowrap;
      }
    }
  }
}

//Charts.js 圖表限制寬高避免在IE爆衝
.chart-box {
  canvas {
    max-width: 100%;
    @include ie-only {
      max-height: 480px !important;
    }
    
  }
}
