//================================================================================

// @include media-breakpoint-down(xs) {}
// @include media-breakpoint-down(sm) {}
// @include media-breakpoint-down(md) {}
// @include media-breakpoint-down(lg) {} XXXX以下
// @include media-breakpoint-between(md, xl) {}  特殊區間
// @include media-breakpoint-up(sm) { ... }   XXXX以上

//================================================================================


//================================================================================
/* SmartMenus Bootstrap 4 Addon (Navbar) */
//================================================================================

/* Carets in collapsible mode (make them look like +/- buttons) */
.navbar-nav.sm-collapsible {
  .sub-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    margin: -0.7em 0 0 0;
    // margin: -0.7em 0.5em 0 0;
    // border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: $border-radius;
    padding: 0;
    width: 1.4em;
    height: 1.4em;
    font-size: 1.25rem;
    font-family: $font-family-icon;
    line-height: 1.4em;
    text-align: center;

    &::before {
      content: '\f067';
    }
  }

  .show>a>.sub-arrow::before {
    content: '\f068';
  }
}

.navbar-dark .navbar-nav.sm-collapsible .nav-link .sub-arrow {
  border-color: rgba(255, 255, 255, 0.1);
}

/* make sure there's room for the carets */
.navbar-nav {
  &.sm-collapsible {
    .has-submenu {
      padding-right: 3em;
    }

    /* keep the carets properly positioned */
    .nav-link,
    .dropdown-item {
      position: relative;
    }
  }

  /* Nav carets in expanded mode */
  &:not(.sm-collapsible) .nav-link .sub-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-left: .3em solid transparent;
  }
}

/* point the arrows up for .fixed-bottom navbars */
.fixed-bottom .navbar-nav:not(.sm-collapsible) {

  .nav-link .sub-arrow,
  &:not([data-sm-skip]) .dropdown-toggle::after {
    border-top: 0;
    border-bottom: .3em solid;
  }
}

/* Dropdown carets in expanded mode */
.navbar-nav {
  &:not(.sm-collapsible) {

    .dropdown-item .sub-arrow,
    &:not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 0;
      height: 0;
      margin-top: -.3em;
      margin-right: 1em;
      border-top: .3em solid transparent;
      border-bottom: .3em solid transparent;
      border-left: .3em solid;
    }

    /* make sure there's room for the carets */
    .dropdown-item.has-submenu {
      padding-right: 2em;
    }
  }

  /* Scrolling arrows for tall menus */
  .scroll-up,
  .scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    height: 20px;
    overflow: hidden;
    text-align: center;
  }

  .scroll-up-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-top: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-left: 7px solid transparent;
  }

  .scroll-down-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-top: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-left: 7px solid transparent;
    top: 6px;
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid transparent;
  }

  /* Add some spacing for 2+ level sub menus in collapsible mode */
  &.sm-collapsible .dropdown-menu .dropdown-menu {
    margin: .5em;
  }

  /* Fix SmartMenus sub menus auto width (subMenusMinWidth/subMenusMaxWidth options) */
  &:not([data-sm-skip]) .dropdown-item {
    white-space: normal;
  }

  &:not(.sm-collapsible) .sm-nowrap>li>.dropdown-item {
    white-space: nowrap;
  }
}

//================================================================================
/* 主要選單設定 */
//================================================================================

header {
  background-color: $white;
  padding: 0;
  box-shadow: 0 1px 2px rgba($black, 0.16);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
  //行動版固定置頂
  // @include break-down {
  //   background-color: $white;
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   z-index: $zindex-fixed;
  // }
}

// 上方選單top-navbar
.top-navbar {
  height: $top-navbar-height;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  background-color: $gray-300;

  >div[class*='container'] {
    @include flex-align-center;
  }

  // @include break-up {
  //   height: auto;
  // }

  //上方訊息選單
  .info-nav {
    font-size: 0.875rem;
    display: none;

    @include break-up {
      @include flex-align-center;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      margin-left: auto;
    }

    .nav-item {
      border-left: dashed 1px $main-color02;

      &:last-child {
        border-right: dashed 1px $main-color02;
      }

      &:hover,
      &:focus,
      &.active {
        background-color: rgba($white, 0.5);
      }
    }

    .nav-link {
      color: $gray-800;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  //文字控制按鈕
  .size-control {
    // margin-left: auto;
    display: flex;
    align-items: center;

    @include break-up {
      // margin-left: 1rem;
      padding-bottom: 0;
    }

    a[class*='size-control-'] {
      @include text-hide;
      width: 25px;
      height: 25px;
      margin: 0 0.125rem;
      display: inline-block;
      border: dashed 1px $gray-800;
      background-repeat: no-repeat;
      background-position: center bottom;
      //background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5Mi4wMDAwMDAwMDAwMDAyIiBoZWlnaHQ9IjE3OTIuMDAwMDAwMDAwMDAwMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCiA8Zz4KICA8dGl0bGU+YmFja2dyb3VuZDwvdGl0bGU+CiAgPHJlY3QgZmlsbD0ibm9uZSIgaWQ9ImNhbnZhc19iYWNrZ3JvdW5kIiBoZWlnaHQ9IjQwMiIgd2lkdGg9IjU4MiIgeT0iLTEiIHg9Ii0xIi8+CiA8L2c+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPHBhdGggZmlsbD0iI2ZmZmZmZiIgaWQ9InN2Z18xIiBkPSJtNzg5LDU1OWwtMTcwLDQ1MHEzMywwIDEzNi41LDJ0MTYwLjUsMnExOSwwIDU3LC0ycS04NywtMjUzIC0xODQsLTQ1MnptLTcyNSwxMTA1bDIsLTc5cTIzLC03IDU2LC0xMi41dDU3LC0xMC41dDQ5LjUsLTE0LjV0NDQuNSwtMjl0MzEsLTUwLjVsMjM3LC02MTZsMjgwLC03MjRsMTI4LDBxOCwxNCAxMSwyMWwyMDUsNDgwcTMzLDc4IDEwNiwyNTcuNXQxMTQsMjc0LjVxMTUsMzQgNTgsMTQ0LjV0NzIsMTY4LjVxMjAsNDUgMzUsNTdxMTksMTUgODgsMjkuNXQ4NCwyMC41cTYsMzggNiw1N3EwLDQgLTAuNSwxM3QtMC41LDEzcS02MywwIC0xOTAsLTh0LTE5MSwtOHEtNzYsMCAtMjE1LDd0LTE3OCw4cTAsLTQzIDQsLTc4bDEzMSwtMjhxMSwwIDEyLjUsLTIuNXQxNS41LC0zLjV0MTQuNSwtNC41dDE1LC02LjV0MTEsLTh0OSwtMTF0Mi41LC0xNHEwLC0xNiAtMzEsLTk2LjV0LTcyLC0xNzcuNXQtNDIsLTEwMGwtNDUwLC0ycS0yNiw1OCAtNzYuNSwxOTUuNXQtNTAuNSwxNjIuNXEwLDIyIDE0LDM3LjV0NDMuNSwyNC41dDQ4LjUsMTMuNXQ1Nyw4LjV0NDEsNHExLDE5IDEsNThxMCw5IC0yLDI3cS01OCwwIC0xNzQuNSwtMTB0LTE3NC41LC0xMHEtOCwwIC0yNi41LDR0LTIxLjUsNHEtODAsMTQgLTE4OCwxNHoiLz4KIDwvZz4KPC9zdmc+);
      background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJmb250IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZm9udCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik00MTYgNDE2aC0yNS44MUwyNTMuMSA1Mi43NmMtNC42ODgtMTIuNDctMTYuNTctMjAuNzYtMjkuOTEtMjAuNzZzLTI1LjM0IDguMjg5LTMwLjAyIDIwLjc2TDU3LjgxIDQxNkgzMmMtMTcuNjcgMC0zMiAxNC4zMS0zMiAzMnMxNC4zMyAzMiAzMiAzMmg5NmMxNy42NyAwIDMyLTE0LjMxIDMyLTMycy0xNC4zMy0zMi0zMi0zMkgxMjYuMmwxNy4xLTQ4aDE1OS42bDE3LjEgNDhIMzIwYy0xNy42NyAwLTMyIDE0LjMxLTMyIDMyczE0LjMzIDMyIDMyIDMyaDk2YzE3LjY3IDAgMzItMTQuMzEgMzItMzJTNDMzLjcgNDE2IDQxNiA0MTZ6TTE2OC4yIDMwNEwyMjQgMTU1LjFsNTUuODIgMTQ4LjlIMTY4LjJ6Ij48L3BhdGg+PC9zdmc+");
      opacity: 0.6;

      &:hover,
      &:active,
      &.active {
        opacity: 1;
      }
    }

    &-sm {
      background-size: 55%;
    }

    &-default {
      background-size: 70%;
    }

    &-lg {
      background-size: 85%;
    }
  }
}

//LOGO
.navbar-brand {
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }

  a {
    @include text-hide;
    width: 180px;
    height: 40px;
    display: block;
    background: url(../images/MASlogo.svg) no-repeat left center;
    background-size: contain;

    @include media-breakpoint-up(xl) {
      width: 250px;
      height: 54px;
    }
  }
}

//主要選單
.navbar-mas {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;

  //上方訊息選單分身隱藏
  .info-nav {
    display: none;
  }

  //主要選單行動版
  @include break-down {
    height: $navbar-mas-height;

    >[class*="container"] {
      justify-content: initial;
    }

    #navbarMasDropdown {
      background-color: $white;
      box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
      position: fixed;
      top: 2rem;
      right: 2rem;
      bottom: 2rem;
      left: 2rem;
      z-index: $zindex-modal;
      overflow-y: auto;
      transition: all .1s;

      @include media-breakpoint-down(sm) {
        right: 1rem;
        left: 1rem;
      }

      //上方訊息選單分身
      .info-nav {
        font-size: 0.813rem;
        padding: 0.5rem;
        margin-top: 1rem;
        // border-top: dotted 1px $main-color02;
        display: flex;
        justify-content: center;

        .nav-item {
          margin: 0.25rem;
        }

        .nav-link {
          color: $white;
          font-weight: $font-weight-bold;
          background-color: $main-color02;
          border: solid 1px $main-color02;

          &:hover,
          &:active {
            background-color: $gray-700;
          }
        }
      }
    }
  }

  //預設選單開關
  .navbar-toggler {
    padding: 0.25rem 0.5rem;
    margin-left: auto;
    border-color: $gray-700;

    &-icon {
      width: 1.125em;
      height: 1.125em;
    }

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 32px;
      padding: 0.125rem;
    }
  }

  //下拉選單內開關
  .toggle-mas {
    position: absolute;
    top: 1rem;
    right: 1rem;

    @include break-up {
      display: none;
    }
  }

  //主選單設定
  .menu-mas {
    > .nav-item {

      &:hover,
      &:active,
      &.active,
      &:focus-within {
        .nav-link {
          color: $main-color01;
          @include break-up {
            border-color: $main-color01;
          }
        }
      }
    }

    .nav-link {
      color: $text-color;
      font-weight: $font-weight-bold;
      padding: 0.625rem 1rem;
      border: solid 1px transparent;

      // &:hover,
      // &:active,
      // &:active,
      // &.active,
      // &:focus-within {
      //   color: $main-color01;
      // }

      &.dropdown-toggle::after {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.125rem;
      }
    }

    .dropdown-menu {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

      >li:not(:last-child) {
        border-bottom: dashed 1px $gray-500;
      }
    }

    .dropdown-item {
      font-size: 0.938rem;
      font-weight: $font-weight-bold;
    }


    @include break-up {
      margin-top: 0.5rem;
      flex-wrap: nowrap;

      .nav-link {
        white-space: nowrap;

        .sub-arrow {
          display: none;
        }
      }

      .dropdown-menu {
        margin-left: 0 !important; //下拉選單置中
        left: 50% !important; //下拉選單置中
        transform: translateX(-50%) !important; //下拉選單置中
      }

      .dropdown-item {
        text-align: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &:hover,
        &:focus,
        &:active {
          color: $white;
          background-color: $gray-700;
        }

        &.active {
          color: $white;
          background-color: darken($main-color01 , 8);
        }
      }
    }

    @include media-breakpoint-up(xxl) {
      >.nav-item:not(:first-child) {
        margin-left: 0.5rem;
      }
    }

    //行動板主要選單
    &.sm-collapsible {
      margin-top: 3.75rem;
      background-color: $gray-200;

      // border-top: dotted 1px $main-color02;
      .nav-item {
        padding: 0.5rem 1rem;
      }

      .nav-link {
        padding: 0.5rem 0;
      }

      .dropdown-menu {
        border: none;
        background-color: $white;

        // >li:not(:last-child) {
        //   border-bottom: dashed 1px $gray-500;
        // }

        .dropdown-item {
          text-align: center;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          &:hover,
          &:focus,
          &:active {
            color: $white;
            background-color: $gray-700;
          }
        }
      }
    }
  }
}